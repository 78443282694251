<template>
  <div :class="[showOutput ? 'col-sm-12 col-2xl-10 ' : 'col-sm-9 col-2xl-8']">
    <b-card class="report-writer" header-class="py-2" body-class="pt-3">
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-5">
            <div class="d-flex align-items-baseline">
              <div class="pr-1 d-flex align-self-center">
                <i class="fa fa-bar-chart fa-lg"></i>
              </div>
              <div>
                <h5 class="mb-0">
                  <b>{{ getMode }}</b>
                  <span
                    class="p-0 pl-1 pb-1  favorite__star__selected"
                    v-if="keyMetricReport"
                    ><b> &#x2605;</b>
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-sm-3 text-center report-output-view px-0">
            <Multiselect
              v-if="!keyMetricReport"
              track-by="value"
              label="text"
              v-model="fiType"
              :allowEmpty="false"
              :showLabels="false"
              :options="fiTypeOptions"
              class="rw-multi-select"
            ></Multiselect>
          </div>
          <div
            class="col-sm-4 text-right d-flex justify-content-end align-items-center"
          >
            <h6 class="mb-0 pr-2 d-inline-block pt-1">
              Show Output
            </h6>
            <c-switch
              type="text"
              variant="success"
              on="Yes"
              off="No"
              :pill="true"
              size="sm"
              :checked="showOutput"
              @change="setOutputView"
              class="mb-0 mt-1"
            ></c-switch>
          </div>
        </div>
      </div>

      <v-wait for="loadingData">
        <template slot="waiting">
          <content-placeholders :rounded="true" class="loader">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div>
          <custom-report
            :elements="reportElements"
            :showOutput="showOutput"
            :selectedReport="selectedReport"
            rendered-in="Report Writer"
          ></custom-report>

          <div class="row">
            <div
              :class="[showOutput ? 'col-sm-9 col-sm-9-mv-70' : 'col-sm-12']"
            >
              <div class="row justify-content-center">
                <div :class="[showOutput ? 'col-sm-6' : 'col-sm-4']">
                  <div class="row">
                    <div class="col-sm-12 pb-1">
                      <b-form-group>
                        <label for="Search" class="mb-1">
                          <b>Report Name</b><i class="pl-1">(required)</i>
                        </label>
                        <b-form-input
                          type="text"
                          name="report_name"
                          class="form-control rounded"
                          placeholder="Enter a Report Name"
                          v-model="reportName"
                          ref="reportName"
                          :class="[
                            $v.reportName.$dirty && $v.reportName.$invalid
                              ? 'is-invalid'
                              : '',
                            'form-control rounded'
                          ]"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          v-if="$v.reportName.$dirty && $v.reportName.$invalid"
                          >report name required</b-form-invalid-feedback
                        >
                        <div
                          class="invalid-feedback d-block info-msg"
                          v-if="showWarning"
                        >
                          <span class="fa fa-info-circle pr-1"></span>
                          {{ this.actualFIType | formatFIType }} is the actual
                          FI Type
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div :class="[showOutput ? 'col-sm-6' : 'col-sm-4']">
                  <b-form-group>
                    <label for="Search" class="mb-1">
                      <b>Description</b><i class="pl-1">(Optional)</i>
                    </label>
                    <b-form-textarea
                      id="description"
                      class="form-control rounded"
                      v-model="reportDescription"
                      rows="2"
                      max-rows="2"
                    >
                    </b-form-textarea>
                  </b-form-group>
                </div>
              </div>
              <div class="row justify-content-center">
                <div :class="[showOutput ? 'col-sm-6' : 'col-sm-4']">
                  <b-form-group>
                    <label for="Search" class="mb-1">
                      <b>Group Access</b><i class="pl-1">(Optional)</i>
                    </label>

                    <multiselect
                      v-model="selectedGroups"
                      :options="userGroups"
                      :multiple="true"
                      :close-on-select="false"
                      label="name"
                      track-by="id"
                      :showLabels="false"
                      placeholder
                      class="simple-select"
                      :searchable="false"
                    >
                      <template slot="selection" slot-scope="{ values }"
                        >{{ values.length }}
                        {{ values.length | pluralize('group') }}
                        selected</template
                      >
                      <template slot="option" slot-scope="group">
                        <div>
                          <input
                            type="checkbox"
                            :checked="isGroupSelected(group.option)"
                            class="mr-1"
                          />
                          {{ group.option.name }}
                        </div>
                      </template>
                    </multiselect>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div
              :class="[showOutput ? 'col-sm-9' : 'col-sm-12', 'text-center']"
            >
              <b-button
                variant="secondary"
                class="rounded ml-3 std-btn"
                @click="backToSelectReport"
              >
                Back
              </b-button>
              <b-button
                variant="primary"
                class="rounded ml-3 std-btn"
                @click="saveReport"
              >
                {{ isEditMode ? 'Update Report' : 'Create Report' }}
              </b-button>
            </div>
          </div>
        </div>
      </v-wait>
    </b-card>
  </div>
</template>

<script>
//global
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import _ from 'lodash'
//ui components
import CustomReport from '../components/CustomReport'
import cSwitch from '@/components/Switch'
import Multiselect from 'vue-multiselect'
//api
import selfServeReportsAPI from '@/api/finapps/self_serve_reports'
//validations
import { required } from 'vuelidate/lib/validators'
//utilities
import deepClone from '@/utilities.js'
import router from '@/router'

export default {
  name: 'ReportEditor',
  props: {},
  components: {
    cSwitch,
    Multiselect,
    CustomReport
  },
  created() {
    this.clearGarbageData()
    this.clearReportDataForEditor()

    this.$wait.start('loadingData')

    if (this.isEditMode) {
      this.actualFIType = this.fiType.value

      Promise.all([this.loadReportData(), this.fetchElementsInfo()]).then(
        () => {
          this.$wait.end('loadingData')
        }
      )
    } else {
      this.fetchElementsInfo().then(() => {
        this.$wait.end('loadingData')
      })
    }
  },
  beforeRouteLeave(to, form, next) {
    if (this.hasUnsavedChanges()) {
      this.$dialog
        .confirm(
          {
            title: 'Alert',
            body:
              'Your preferences are not saved, this action may result in loss of data. Do you still want to continue?'
          },
          {
            cancelText: 'Cancel'
          }
        )
        .then(() => {
          this.setCustomReport({
            id: null,
            name: null,
            group_ids: [],
            description: null,
            report_elements: null,
            fi_type: 'both'
          })
          next()
        })
        .catch(() => {
          next(false)
        })
    } else {
      this.setCustomReport({
        id: null,
        name: null,
        group_ids: [],
        description: null,
        report_elements: null,
        fi_type: 'both'
      })
      next()
    }
  },
  data() {
    return {
      actualFIType: 'both'
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      showOutput: state => state.showOutput,
      fiTypeOptions: state => state.fiTypeOptions,
      selectedReport: state => state.selectedReport,
      reportElements: state => state.reportElements,
      savedReportElements: state => state.savedReportElements
    }),
    ...mapState('Authentication', {
      userGroups: state => state.userGroups
    }),
    ...mapFields('ReportWriter', {
      reportName: 'selectedReport.name',
      reportGroupIDs: 'selectedReport.group_ids',
      reportDescription: 'selectedReport.description',
      reportCategory: 'selectedReport.category'
    }),
    getMode() {
      return this.isEditMode
        ? this.keyMetricReport
          ? 'Edit Key Metric Report'
          : 'Edit Custom Report'
        : 'Create Custom Report'
    },
    fiType: {
      get() {
        return this.fiTypeOptions.find(
          ft => ft.value === this.selectedReport.fi_type
        )
      },
      set(type) {
        this.setReportFIType(type.value)
      }
    },
    selectedGroups: {
      get() {
        return this.userGroups.filter(ug => {
          return this.reportGroupIDs.includes(ug.id)
        })
      },
      set(groups) {
        this.setReportGroupIDs(groups.map(g => g.id))
      }
    },
    isEditMode() {
      return this.selectedReport.id !== null
    },
    showWarning() {
      return (
        this.isEditMode && this.actualFIType !== this.selectedReport.fi_type
      )
    },
    keyMetricReport() {
      return this.reportCategory === 'key_metrics'
    }
  },
  methods: {
    ...mapActions('ReportWriter', ['fetchElementsInfo']),
    ...mapMutations('ReportWriter', [
      'setOutputView',
      'setCustomReport',
      'setReportElements',
      'setReportGroupIDs',
      'setReportFIType',
      'clearGarbageData',
      'clearReportDataForEditor',
      'setSavedReportElements'
    ]),
    backToSelectReport() {
      router.push('/super_admin/report_writer/select_report')
    },
    loadReportData() {
      return selfServeReportsAPI.edit(this.selectedReport.id).then(() => {
        this.setSavedReportElements(deepClone(this.reportElements))
      })
    },
    saveReport() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isEditMode ? this.updateReport() : this.createReport()
      }
    },
    createReport() {
      selfServeReportsAPI
        .create(
          this.reportName,
          this.reportDescription,
          this.reportGroupIDs,
          this.fiType.value
        )
        .then(res => {
          if (res) {
            this.actualFIType = res.report.fi_type
            res.report.fi_type = this.fiType.value
            this.setCustomReport(res.report)
            this.setSavedReportElements(deepClone(this.reportElements))
            this.$toasted.global.action_success('Report created successfully.')
          }
        })
    },
    updateReport() {
      selfServeReportsAPI
        .update(
          this.selectedReport.id,
          this.reportName,
          this.reportDescription,
          this.reportGroupIDs,
          this.fiType.value
        )
        .then(res => {
          if (res) {
            this.actualFIType = res.report.fi_type
            this.setSavedReportElements(deepClone(this.reportElements))
            this.$toasted.global.action_success('Report updated successfully.')
          }
        })
    },
    hasUnsavedChanges() {
      if (this.isEditMode) {
        return !_.isEqual(this.reportElements, this.savedReportElements)
      } else {
        return this.reportElements.length
      }
    },
    isGroupSelected(option) {
      return this.reportGroupIDs.includes(option.id)
    }
  },
  validations: {
    reportName: {
      required
    }
  }
}
</script>
<style lang="scss" scoped>
.favorite__star__selected {
  color: #a7ca77;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.25rem;
  cursor: pointer;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
  -webkit-text-stroke: 0.6px grayscale($color: #686262fb);
}
@media (min-width: 1920px) {
  .col-2xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-2xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}
</style>
